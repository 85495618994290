import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "plannedStart",
    "plannedEnd",
    "effectiveStart",
    "effectiveEnd",
  ];

  connect() {
    this.initializePlannedDatesWithEffectiveDates();
  }

  initializePlannedDatesWithEffectiveDates() {
    const plannedStartDate = this.plannedStartTarget.value;
    const plannedEndDate = this.plannedEndTarget.value;
    const effectiveStartDate = this.effectiveStartTarget.value;
    const effectiveEndDate = this.effectiveEndTarget.value;

    if (
      !(plannedStartDate && plannedEndDate) &&
      effectiveStartDate &&
      effectiveEndDate
    ) {
      this.plannedStartTarget.value = this.effectiveStartTarget.value;
      this.plannedEndTarget.value = this.effectiveEndTarget.value;
    }

    if (plannedStartDate && !plannedEndDate) {
      this.plannedEndTarget.value = this.plannedStartTarget.value;
    }

    if (effectiveStartDate && !effectiveEndDate) {
      this.effectiveEndTarget.value = this.effectiveStartTarget.value;
    }

    if (new Date(effectiveStartDate) > new Date(effectiveEndDate)) {
      this.effectiveEndTarget.value = this.effectiveStartTarget.value;
    }

    if (new Date(plannedStartDate) > new Date(plannedEndDate)) {
      this.plannedEndTarget.value = this.plannedStartTarget.value;
    }
  }

  setEffectiveDateSameAsPlannedDate() {
    this.effectiveStartTarget.value = this.plannedStartTarget.value;
    this.effectiveEndTarget.value = this.plannedEndTarget.value;
  }
}
